import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import ExpressUnderstanding from "../../../components/ui/specialty/express/ExpressUnderstanding";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import ExpressImage from "../../../../src/resources/images/express.jpg";

import img1 from "../../../resources/images/icon/2. 특송통관/1. 수입통관, 수출통관.png";
import img2 from "../../../resources/images/icon/2. 특송통관/2. FTA 적용안내 및 신청.png";
import img3 from "../../../resources/images/icon/2. 특송통관/3. 원산지 보수작업 신청.png";
import img4 from "../../../resources/images/icon/2. 특송통관/4. 분할 통관 보수작업 신청.png";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";

const ExpressPage = () => {
  return (
    <PageTemplate
      large
      image={ExpressImage}
      title="특송통관"
      phrase="최상의 전자상거래 업무프로그램과 축적된 노하우를 활용한 고품질 서비스를 제공합니다."
      description="최신의 IT 시스템 기반 구축과 축적된 경험과 특화된 전문 인력으로
      특송업체별 다양한 요구와 업무프로세스를 충족한 고객 맞춤형 양질의 서비스
      구현, 최상의 전자상거래 프로그램 도입과 전자상거래 전담자를 배치 신속
      정확한 서비스 제공, 특송화물 전문 관세사를 배치 수입통관에 수반되는
      애로사항 및 각종 세관절차에 대한 양질의 고객 맞춤형 서비스를 제공합니다."
    >
      <TitleTemplate
        title="특송물품 통관 구분"
        subHeader="대상 물품에 맞는 가장 적절하고 신속한 신고로 고객만족을 전달하고 있습니다."
        noImage
      />
      <ExpressUnderstanding />
      <TitleTemplate
        title="업무 범위"
        subHeader="특송총괄본부가 차별화된 특송서비스를 수행하고 있습니다."
        noImage
      />
      <WorkScopes
        contents={[
          { title: "수입통관", icon: img1 },
          { title: "수출통관", icon: img1 },
          { title: "FTA 적용안내 및 신청", icon: img2 },
          { title: "원산지 보수작업 신청", icon: img3 },
          { title: "분할 통관 보수작업 신청", icon: img4 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
        noImage
      />
      <DiamondScope
        contents={[
          {
            title: "MAN POWER",
            text: "특송통관 특화 전문가의 신속 정확한 업무처리",
          },
          {
            title: "TASK-FORCE",
            text: "최상의 전자상거래 프로그램의 도입 및 특송화물 전담부서 운영",
          },
          {
            title: "Know-how",
            text: "다양한 고객 요구를 충족할 축적된 특송화물 통관 노하우",
          },
          {
            title: "Customized System",
            text: "고객맞춤형 프로세스 구현",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["sykim0608"]} />
    </PageTemplate>
  );
};
export default ExpressPage;
