import PageTemplate from "../../components/ui/common/template/PageTemplate";
import MemberListTemplate from "../../components/ui/members/MemberListTemplate";
import ProfessionalImage from "./../../resources/images/professional.jpg";
const Profe1ssionalsPage = () => {
  const groups = [
    {
      category: "수출입통관",
      members: [
        "ezpass",
        "hd11234",
        "son0077",
        "lgman",
        "k1234",
        "jina1012",
        "yoojung",
        "xanhy",
        "pass911",
        "woon4545",
        "donghee7747",
      ],
    },
    { category: "환급", members: ["yeonghwan", "meenss", "lily93"] },
    {
      category: "요건/검역",
      members: ["tnt-2000", "pass911", "josep62", "jhpark0113"],
    },
    { category: "과세가격", members: ["jskim"] },
    {
      category: "품목분류",
      members: ["ccam7413", "jina1012"],
    },
    {
      category: "식품",
      members: ["sunggun"],
    },
    {
      category: "특송",
      members: ["suyun"],
    },
    { category: "FTA", members: ["cupark", "jskim"] },
    {
      category: "AEO",
      members: ["yeonghwan", "gilho", "kht3073"],
    },
    {
      category: "기업심사",
      members: ["son0077", "yeonghwan"],
    },
    {
      category: "행정쟁송",
      members: ["y2kykim", "parksw11", "gilho", "solgae8"],
    },
  ];
  return (
    <PageTemplate
      title="분야별 전문가"
      phrase="준비된 관세 Specialist와 함께하세요."
      large
      image={ProfessionalImage}
    >
      <MemberListTemplate groups={groups} />
    </PageTemplate>
  );
};
export default Profe1ssionalsPage;
