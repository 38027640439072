import TitleTemplate from "../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import WorkScopes from "../../components/ui/specialty/WorkScopes";
import DevImage from "../../resources/images/dev.jpg";
import ContactPoints from "../../components/ui/members/ContactPoints";
import DiamondScope from "../../components/ui/specialty/DiamondScope";
import workscope1Image from "../../resources/images/icon/14. IT/1. 통관 정보제공.png";
import workscope2Image from "../../resources/images/icon/14. IT/2. 수입자동차 통합 신고 시스템.png";
import workscope3Image from "../../resources/images/icon/14. IT/3. 수입차량 부품 통합 신고 시스템.png";
import workscope4Image from "../../resources/images/icon/14. IT/4. 원산지 현품확인 시스템.png";
import workscope5Image from "../../resources/images/icon/14. IT/5. 개소세 환급 프로젝트.png";
import workscope6Image from "../../resources/images/icon/14. IT/6. 화물 정보 관리.png";
import workscope7Image from "../../resources/images/icon/14. IT/7. RMS.png";
import workscope8Image from "../../resources/images/icon/14. IT/8. 특송통관 시스템.png";

const DevPage = () => {
  return (
    // 20220713 비전 2030 내용 반영할 것
    <PageTemplate
      large
      image={DevImage}
      title="IT System"
      aone
      phrase="관세업계 미래를 이끌어 갈 IT First-mover"
    >
      <p>
        에이원은 TCMS를 시작으로 RMS, IVIS 등 다양한 컨텐츠와 플랫폼을 개발하여
        적법성, 업무 효율성 및 고객과의 연결성을 강화해오고 있습니다. 일찍부터
        자체 기술로 OCR 및 PDF 텍스트 변환을 업무에 적용해왔으며, 지속적인 최신
        trend 연구를 통해 IT 기술 고도화에 힘써오고 있습니다. 이제 곧 도입될
        스마트 통관 및 스마트 컨버팅 시스템을 통해 한층 더 강화된 Compliance로
        고객 여러분과 함께 하겠습니다.
      </p>

      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="고객과 직원 needs에 부합하는 솔루션을 만들기 위해 노력합니다."
      />
      <WorkScopes
        contents={[
          { title: "통관 정보제공 서비스", icon: workscope1Image },
          { title: "수입 완성차 통합 신고 시스템", icon: workscope2Image },
          { title: "수입 차량부품 통합 신고 시스템", icon: workscope3Image },
          { title: "원산지 현품 사진관리 시스템", icon: workscope4Image },
          { title: "개별소비세 환급 관리 시스템", icon: workscope5Image },
          { title: "화물정보 알림 시스템", icon: workscope6Image },
          { title: "RMS (w/ 레디코리아)", icon: workscope7Image },
          { title: "특송통관 시스템(w/ 로그인네트웍)", icon: workscope8Image },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="앞선 기술과 풍부한 경험을 바탕으로 최적의 솔루션을 제공합니다."
      />
      <DiamondScope
        contents={[
          {
            title: "Client First",
            text: "관세 솔루션 전문 컨설턴트가 정확한 고객의 needs 분석",
          },
          {
            title: "Technology",
            text: "OCR, AI 등 기술 trend의 지속적 연구 및 분석",
          },
          {
            title: "Professionals",
            text: "다양한 stack의 개발자들로 꾸려진 전문 개발인력 보유",
          },
          {
            title: "Experience",
            text: "관세, 경리, 리스크 분석, 경영 등 다양한 솔루션 개발 경험",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["cupark"]} />
    </PageTemplate>
  );
};
export default DevPage;
